import { ApiCode } from '@/const/api'
import { initialAbilities } from '@/libs/acl/config'
import ability from '@/libs/acl/ability'
import {
  getIdToken, getLoginType, getRefreshToken, getToken, isUserLoggedIn, removeIdToken, removeLoginType,
  removeRefreshToken, removeToken, removeUser, setRefreshToken, setToken,
} from '@/auth/utils'
import router from '@/router'
import { LOGIN_TYPE } from '@/const/type'
import jwtDefaultConfig from './jwtDefaultConfig'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  subscribers = []

  timer = null

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = getToken()
        if (!isUserLoggedIn()) {
          config.headers['X-Tenant-Id'] = process.env.VUE_APP_TENANT_ID
        }
        // If token is present add it to request's Authorization Header
        if (accessToken && config.url !== this.jwtConfig.refreshEndpoint) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        const originalRequest = config
        if (response && response.status === 401) {
          const { data } = response
          const { code } = data
          if (code === ApiCode.TOKEN_EXPIRED) {
            const loginType = getLoginType()
            if (loginType === LOGIN_TYPE.NORMAL) {
              this.refreshToken().then(r => {
                const dataRefresh = r.data
                if (dataRefresh.code === ApiCode.SUCCESS) {
                  setToken(dataRefresh.data.accessToken)
                  setRefreshToken(dataRefresh.data.refreshToken)
                  this.onAccessTokenFetched(dataRefresh.data.accessToken)
                } else {
                  this.logoutNormal()
                }
              }).catch(() => {
                this.logoutNormal()
              })
              return new Promise(resolve => {
                this.addSubscriber(accessToken => {
                  // Make sure to assign accessToken according to your response.
                  // Check: https://pixinvent.ticksy.com/ticket/2413870
                  // Change Authorization header
                  originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                  resolve(this.axiosIns(originalRequest))
                })
              })
            }
            if (loginType === LOGIN_TYPE.SSO) {
              this.logoutSso()
            }
          }
        }
        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  logoutNormal() {
    this.handleLogout()
    router.go(0)
  }

  logoutSso() {
    const idToken = getIdToken()
    this.handleLogout()
    window.location.href = `${process.env.VUE_APP_SSO_URL}/oidc/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${process.env.VUE_APP_SSO_LOGOUT_CALLBACK_URL}`
  }

  handleLogout() {
    this.removeStorage()
    ability.update(initialAbilities)
  }

  removeStorage() {
    removeToken()
    removeRefreshToken()
    removeUser()
    removeLoginType()
    removeIdToken()
  }

  async login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  async verify(...args) {
    return this.axiosIns.get(this.jwtConfig.verifyEndpoint, ...args)
  }

  async verifySso(...args) {
    return this.axiosIns.get(this.jwtConfig.verifySsoEndpoint, ...args)
  }

  async refreshToken() {
    return this.axiosIns.post(
      this.jwtConfig.refreshEndpoint,
      { refreshToken: getRefreshToken() },
      { headers: { 'X-Tenant-Id': process.env.VUE_APP_TENANT_ID } },
    )
  }
}
